// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../components/navBar/wot_link.cur", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tanks_tank__kZmP0{\r\n    width: 49px;\r\n    height: 49px;\r\n    position: absolute;\r\n   z-index: -239239;\r\n    \r\n}\r\n.Tanks_tank__kZmP0 img{\r\n    width: 55px;\r\n    height: 40px;\r\ncursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),pointer;\r\n\r\n}\r\n\r\n.Tanks_active__grjFQ{\r\n    box-shadow: 10px 5px 5px rgba(204, 223, 204, 0);\r\n  \r\n}\r\n", "",{"version":3,"sources":["webpack://./src/gameComponents/Tanks/Tanks.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;GACnB,gBAAgB;;AAEnB;AACA;IACI,WAAW;IACX,YAAY;AAChB,uDAAyD;;AAEzD;;AAEA;IACI,+CAA+C;;AAEnD","sourcesContent":[".tank{\r\n    width: 49px;\r\n    height: 49px;\r\n    position: absolute;\r\n   z-index: -239239;\r\n    \r\n}\r\n.tank img{\r\n    width: 55px;\r\n    height: 40px;\r\ncursor: url(../../components/navBar/wot_link.cur),pointer;\r\n\r\n}\r\n\r\n.active{\r\n    box-shadow: 10px 5px 5px rgba(204, 223, 204, 0);\r\n  \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tank": "Tanks_tank__kZmP0",
	"active": "Tanks_active__grjFQ"
};
export default ___CSS_LOADER_EXPORT___;
