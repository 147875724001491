// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.movescount_block__xMXta{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 10px;\r\n    margin-left: -100px;\r\n}\r\n.movescount_activeTeam__y7HXT {\r\npadding: 0 10px;\r\n}\r\n.movescount_count__Td0Ul {\r\npadding: 0 10px;\r\n\r\n}\r\n.movescount_nextTeam__F2aR2{\r\n    border: 1px solid black;\r\npadding: 0 10px;\r\ncursor: pointer;\r\n}\r\n\r\n.movescount_nextTeam__F2aR2:hover{\r\n    border: 1px solid rgb(255, 255, 255);\r\n    background-color: rgb(95, 88, 88);\r\npadding: 0 10px;\r\ncursor: pointer;\r\ntransition: 1s;\r\n}", "",{"version":3,"sources":["webpack://./src/gameComponents/movesCount/movescount.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;AACA;AACA,eAAe;AACf;AACA;AACA,eAAe;;AAEf;AACA;IACI,uBAAuB;AAC3B,eAAe;AACf,eAAe;AACf;;AAEA;IACI,oCAAoC;IACpC,iCAAiC;AACrC,eAAe;AACf,eAAe;AACf,cAAc;AACd","sourcesContent":["\r\n.block{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 10px;\r\n    margin-left: -100px;\r\n}\r\n.activeTeam {\r\npadding: 0 10px;\r\n}\r\n.count {\r\npadding: 0 10px;\r\n\r\n}\r\n.nextTeam{\r\n    border: 1px solid black;\r\npadding: 0 10px;\r\ncursor: pointer;\r\n}\r\n\r\n.nextTeam:hover{\r\n    border: 1px solid rgb(255, 255, 255);\r\n    background-color: rgb(95, 88, 88);\r\npadding: 0 10px;\r\ncursor: pointer;\r\ntransition: 1s;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": "movescount_block__xMXta",
	"activeTeam": "movescount_activeTeam__y7HXT",
	"count": "movescount_count__Td0Ul",
	"nextTeam": "movescount_nextTeam__F2aR2"
};
export default ___CSS_LOADER_EXPORT___;
