import { useEffect } from "react";
import { Header } from "../../components/header/header";
import { useLocation, useParams } from "react-router-dom";

export const MainPage = () => {
  let location = useLocation()

  return (
    <>
      <Header />
 
    </>
  );
};
