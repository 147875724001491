import { useState } from "react";
import { ICell, ICoordinatesTank, IDataField } from "../types/types";


let array:ICell[]
export const VectorVizual =(cell:ICell,tank:ICoordinatesTank,cells:ICell[],col:IDataField) => {
      


if (col.type === 1) {

        array = [


      
                {row:cell.row-1,col:cell.col,kef:1,id:1},
                {row:cell.row+1,col:cell.col,kef:1,id:1},
                {row:cell.row-1,col:cell.col-1,kef:1,id:1},
                {row:cell.row,col:cell.col-1,kef:1,id:1},
                {row:cell.row-1,col:cell.col+1,kef:1,id:1},
                {row:cell.row,col:cell.col+1,kef:1,id:1},
                
                ]

} else {

        array = [


      
                {row:cell.row-1,col:cell.col,kef:1,id:1},
                {row:cell.row+1,col:cell.col,kef:1,id:1},
                {row:cell.row+1,col:cell.col-1,kef:1,id:1},
                {row:cell.row,col:cell.col-1,kef:1,id:1},
                {row:cell.row+1,col:cell.col+1,kef:1,id:1},
                {row:cell.row,col:cell.col+1,kef:1,id:1},
                
                ]



}







let gfgf = 0
 console.log(cells)

 let ggg = cells.length
      let s5 = cells.find((cell1) =>  (cell.row-1 ===cell1.row && cell.col === cell1.col) || (cell.row+1 === cell1.row && cell1.col === cell.col) || (cell.row-1 === cell1.row && cell1.col === cell.col-1)  || (cell.row === cell1.row && cell1.col === cell.col-1)  || (cell.row-1 === cell1.row && cell1.col === cell.col+1) || (cell.row === cell1.row && cell1.col === cell.col+1))
      let s6 = cells.find((cell1) =>  (cell.row-1 ===cell1.row && cell.col === cell1.col) || (cell.row+1 === cell1.row && cell1.col === cell.col) || (cell.row+1 === cell1.row && cell1.col === cell.col-1)  || (cell.row === cell1.row && cell1.col === cell.col-1)  || (cell.row+1 === cell1.row && cell1.col === cell.col+1) || (cell.row === cell1.row && cell1.col === cell.col+1) )
      let s = array.find((cell1,index,arr) =>   (cell.col+1 ===cell1.col) && (cell.row+1 ===cell1.row ) )
      let s56 = cells.find((cell1,index,arr) =>   (cell.col-1 ===arr[ggg-2]?.col) && (cell.row+1 ===arr[ggg-2]?.row ) )
      let s1 = array.find((cell1) => cell.col+1 === cell1.col && cell.row === cell1.row)
        console.log(s,array)
                  

      
    
        
      
          


 
     

       

        if ((col.type === 1 && s5) || (col.type === 2 && s6)) {
             console.log( cells[ggg-2],cell)
                gfgf = 2
                if ( cells[ggg-1]?.col % 2 !== 0 &&  (cell.col+1 === cells[ggg-2]?.col && cell.row+1 === cells[ggg-2]?.row)  ) {
                         console.log(1)
                        gfgf = 4
            
                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col-1 === cells[ggg-2]?.col && cell.row+1 === cells[ggg-2]?.row)) {
                        console.log(1)
                        gfgf = 4
                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col-1 === cells[ggg-2]?.col && cell.row === cells[ggg-2]?.row)) {
                        console.log(1)
                        gfgf = 4

                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col+1 === cells[ggg-2]?.col && cell.row === cells[ggg-2]?.row)) {
                        gfgf = 4
                        console.log(1)
                  } else if ( cells[ggg-1]?.col % 2 === 0 && (cell.col-1 === cells[ggg-2]?.col && cell.row-1 === cells[ggg-2]?.row) ) {
                        console.log(1)
                        gfgf = 4
                  } else if ( cells[ggg-1]?.col % 2 === 0 && (cell.col-1 === cells[ggg-2]?.col && cell.row-1 === cells[ggg-2]?.row)) {
                        console.log(1)
                        gfgf = 4

                  } else if ( cells[ggg-1]?.col % 2 === 0 && (cell.col+1 === cells[ggg-2]?.col && cell.row === cells[ggg-2]?.row)) {
                        gfgf = 4
                        console.log(1)
                  } else if ( cells[ggg-1]?.col % 2 === 0 && (cell.col+1 === cells[ggg-2]?.col && cell.row === cells[ggg-2]?.row)) {
                        gfgf = 4
                        console.log(1)
                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col === cells[ggg-2]?.col && cell.row+1 === cells[ggg-2]?.row)) {
                        console.log(1)
                        gfgf = 4
                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col+1 === cells[ggg-2]?.col && cell.row-1 === cells[ggg-2]?.row)) {
                        console.log(1)
                        gfgf = 4

                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col+1 === cells[ggg-2]?.col && cell.row === cells[ggg-2]?.row)) {
                        gfgf = 4
                        console.log(1)
                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col-1 === cells[ggg-2]?.col && cell.row-1 === cells[ggg-2]?.row) ) {
                        console.log(1)
                        gfgf = 4
                  } else if ( cells[ggg-1]?.col % 2 !== 0 && (cell.col === cells[ggg-2]?.col && cell.row-1 === cells[ggg-2]?.row)) {
                        console.log(1)
                        gfgf = 4
 
                  } 

        }

            
        else if ( tank.type === 1 &&   ( ( cell.row === tank.row-1 && cell.col === tank.col-1 ) || ( cell.row === tank.row-1 && cell.col === tank.col  ) || ( cell.row === tank.row-1 && cell.col === tank.col+1 ) || ( cell.row === tank.row && cell.col === tank.col-1 ) || ( cell.row === tank.row+1 && cell.col === tank.col ) || ( cell.row === tank.row && cell.col === tank.col+1 )  ) ) {
              gfgf =1
            
       

            
        }

         
                       
         
       
       

console.log(gfgf)


 return gfgf
 
}